<template>
  <el-form class="row" ref="form" :model="riskForm" :rules="rules" :disabled="loading"
           v-if="riskForm !== null">
    <div class="col-md-4">
      <el-form-item prop="structures">
        <firm-structure-tree :firm-structure="structure"
                           v-model="riskForm.structures"></firm-structure-tree>
      </el-form-item>
    </div>
    <div class="col-md-8">
      <portlet color="green" title="Informations" type="form">
        <div class="row">
          <div class="col-md-12">
            <el-form-item prop="characterId">
              <div class="row d-flex align-items-center">
                <div class="col-md-4 text-right">
                  <span slot="label">Nature du risque</span>
                </div>
                <div class="col-md-8">
                  <el-select v-model="riskForm.characterId" placeholder="Select" filterable>
                    <el-option
                      v-for="character in riskCharacterSorted"
                      :key="character.id"
                      :label="character.label"
                      :value="character.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="col-md-12">
            <el-form-item>
              <div class="row d-flex align-items-center">
                <div class="col-md-4 text-right">
                  <span slot="label">Description</span>
                </div>
                <div class="col-md-8">
                  <el-input type="textarea" v-model="riskForm.description"></el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-section"></div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <el-form-item>
              <div class="row d-flex align-items-center">
                <div class="col-md-4 text-right">
                  <span slot="label">Prévention organisationnelle existante</span>
                </div>
                <div class="col-md-8">
                  <el-input type="textarea"
                            v-model="riskForm.organizationalArrangements"></el-input>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="col-md-12">
            <el-form-item>
              <div class="row d-flex align-items-center">
                <div class="col-md-4 text-right">
                  <span slot="label">Prévention collective existante</span>
                </div>
                <div class="col-md-8">
                  <el-input type="textarea" v-model="riskForm.collectiveArrangements"></el-input>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="col-md-12">
            <el-form-item>
              <div class="row d-flex align-items-center">
                <div class="col-md-4 text-right">
                  <span slot="label">Prévention individuelle existante</span>
                </div>
                <div class="col-md-8">
                  <el-input type="textarea" v-model="riskForm.individualArrangements"></el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-section"></div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <el-form-item>
              <div class="row d-flex align-items-center">
                <div class="col-md-4 text-right">
                  <span slot="label">Pistes d'amélioration proposées</span>
                </div>
                <div class="col-md-8">
                  <el-input type="textarea" v-model="riskForm.evrp"></el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </portlet>
    </div>

    <portal to="form-actions">
      <el-button @click="$emit('cancel')">Fermer</el-button>
      <el-button type="primary" @click="valid">Enregistrer</el-button>
    </portal>
  </el-form>
</template>

<style type="text/css">
  .col-md-4.text-right {
    line-height: 1.6;
  }
</style>

<script lang="ts">
import { namespace } from 'vuex-class';
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { FormComponent, VForm } from '@/types/v-form';

import FirmStructureTree from '@/components/utils/FirmStructureTree.vue';

import Risk from '@models/proRisk/Risk';
import FirmStructure from '@models/firm/FirmStructure';
import RiskCharacter from '@models/proRisk/RiskCharacter';

import RiskForm from '@modules/professional-risk/form/RiskForm';
import RequestSaveForm from '@/utils/RequestSaveForm';

import * as Rules from '@/utils/CustomFormRules';
import _ from 'lodash';

const firmModule = namespace('firm');

const riskRepository = namespace('repositories/risk');
const riskCharacterRepository = namespace('repositories/riskCharacter');

@Component({
  components: {
    FirmStructureTree,
  },
})
export default class ProRiskForm extends Vue implements FormComponent {
  @firmModule.Getter('getOrganization')
  structure!: FirmStructure[];

  @riskRepository.Action('insertOrUpdate')
  save!: (risk: RiskForm) => Promise<void>;

  @riskCharacterRepository.Getter('getAll')
  riskCharacters!: Array<RiskCharacter>;

  @riskCharacterRepository.Action('findAll')
  fetchRiskCharacter!: () => void;

  @Prop()
  risk!: Risk;

  loading: boolean = false;
  isModify: boolean = true;

  $refs!: {
    form: VForm,
  };

  riskForm: RiskForm | null = null;

  get riskCharacterSorted() {
    if (this.riskCharacters) {
      return _.orderBy(this.riskCharacters, [rc => rc.label.toLowerCase()], ['asc']);
    }
    return [];
  }

  get rules() {
    return {
      characterId: [
        { required: true, message: 'Cette valeur ne doit pas être vide.', trigger: 'blur' },
      ],
      structures: [
        {
          message: 'Veuillez renseigner une affectation',
          trigger: 'blur',
          validator: Rules.validateArray,
        },
      ],
    };
  }

  @Watch('risk', { deep: true, immediate: true })
  onRiskChange(n: Risk | null, o: Risk) {
    if (n !== null) {
      this.riskForm = new RiskForm(n);
    }
  }

  created() {
    this.fetchRiskCharacter();
  }

  async valid(): Promise<any> {
    let validForm = false;
    let errorFields = {};
    this.$refs.form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (this.riskForm !== null && validForm) {
      this.loading = true;
      return this.saveForm(this.riskForm).finally(() => {
        this.loading = false;
      });
    }

    return Promise.reject();
  }

  @RequestSaveForm({
    validMsg: 'Mise à jour effectuée. Pensez à (ré)enregistrer le plan d\'action si les assignations ont changé.',
    errorMsg: 'Erreur lors de la sauvegarde de votre risque.',
  })
  async saveForm(riskForm: RiskForm) {
    return this.save(riskForm).then((response) => {
      this.$emit('valid');
      return response;
    });
  }
}
</script>
